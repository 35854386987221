import apolloUtil from '@/service/ApolloUtil'

import accountsQuery from '@/service/account/AccountsQuery.gql'
import resetPasswordMutation from '@/service/account/ResetPasswordMutation.gql'

class AccountService {

  search(params) {
    return apolloUtil.query(accountsQuery, params)
      .then((response) => response?.data?.accounts)
  }

  resetPassword(params) {
    return apolloUtil.mutate(resetPasswordMutation, params)
  }

}

export default new AccountService()
