<template>
  <smart-table :title="translations.manageAccounts"
               :headers="headers()"
               :default-sort="defaultSort()"
               :search-function="searchFunction">

    <template v-slot:results="{ item: item }">
      <!-- name -->
      <td>
        {{ item.name }}
      </td>

      <!-- email -->
      <td>
        {{ item.email }}
      </td>

      <!-- reset password -->
      <td>
        <div class="d-flex justify-space-between reset-password">
          <v-text-field v-model="item.newPassword" :placeholder="translations.addNewPassword"
                        dense hide-details autocomplete="off" />

          <v-btn v-show="item.newPassword" :loading="item.loading"
                 color="main" class="white--text" small
                 @click="resetPasswordHandler(item)">
            {{ translations.reset }}
          </v-btn>
        </div>
      </td>
    </template>
  </smart-table>
</template>


<script>
import { translations } from '@/utils/common'

import SmartTable from '@/components/SmartTable.vue'

import AccountService from '@/service/account/AccountService.js'

export default {
  name: 'Account',

  components: {
    SmartTable
  },

  data() {
    return {
      translations: translations
    }
  },

  methods: {
    headers() {
      const headers = []

      headers.push({
        type: 'TEXT',
        name: 'name',
        label: translations.username,
        sort: 'name'
      })

      headers.push({
        type: 'TEXT',
        name: 'email',
        label: translations.email,
        sort: 'email'
      })

      headers.push({
        type: 'LABEL',
        name: 'resetPassword',
        label: translations.resetPassword,
        help: translations.resetPasswordHelp
      })

      return headers
    },

    defaultSort() {
      return [
        { name: 'name', value: 'ASC' }
      ]
    },

    searchFunction(params) {
      return AccountService.search(params)
    },

    async resetPasswordHandler(item) {
      // workaround to wake up the loading
      item.newPassword = item.newPassword + ' '
      item.loading = true

      // reset the password
      await AccountService.resetPassword({
          accountId: item.id,
          newPassword: item.newPassword.trim()
        })
        .then(() => {
          this.$root.vtoast.show({ message: translations.passwordReset })
        })
        .finally(() => {
          item.newPassword = null
          item.loading = false
        })
    }
  }
}
</script>


<style>
.reset-password,
.reset-password .v-input__control,
.reset-password .v-input__slot {
  width: 200px !important;
}
</style>
